export function debounce(fn: Function, wait: number){
    let timer: number;
    return function(...args: Function extends ((...args: infer P) => any) ? P : never[]){
        timer && clearTimeout(timer); // clear any pre-existing timer

        const context = this; // get the current context
        timer = window.setTimeout(()=>{
            fn.apply(context, args); // call the function if time expires
        }, wait);
    }
}
