import.meta.glob([ '@images/**', '!@images/assets/**' ]);
import.meta.env.DEV && import("@styles/main.sass");

import {createApp} from "petite-vue";
import {isInViewport} from "./utils/is-in-viewport";
import {initMap} from "./map";
import initArchiveProgram from "./views/archive-program";

interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

(() => { // Hiding header
    const headerEl = document.querySelector<HTMLElement>('[data-header]'),
        headerScope: Header = {
            opened: false,
            handleOpenedHeader(state) {
                document.body.classList.toggle("u-hiddenOverflow", state)
                hidingOnScroll()
            }
        }

    headerEl && createApp(headerScope).mount(headerEl)
    if (!headerEl) return

    let lastScroll = 0

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > 480) {
            headerEl.classList.add('is-sticked')
            headerEl.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
        } else {
            headerEl.classList.remove('is-sticked', 'is-up')
        }

        lastScroll = currentScroll;
    }

    hidingOnScroll();
    window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });

    const megaNavigations = headerEl.querySelectorAll<HTMLElement>('.SubNavigation--mega')
    headerEl.querySelector('.HeaderInner').addEventListener('mouseenter', () => {
        megaNavigations.forEach(megaMenu => {
            const icon = megaMenu.previousElementSibling.querySelector('svg')
            if (!icon) return

            const iconPosition = icon.getBoundingClientRect()
            megaMenu.parentElement.style.setProperty(
                '--navigationSubmenuPositionOrigin',
                `${(iconPosition.left - Math.max((window.innerWidth - 1224) / 2, 0) + iconPosition.width / 2) / Math.min(1204, window.innerWidth) * 100}%`
            )
        })
    })

    headerEl.querySelectorAll('[data-submenu-toggle]')
        .forEach(toggle => toggle.addEventListener('click', e => {
            e.preventDefault()
            toggle.closest('li').classList.toggle('is-open')
        }))
})();

initArchiveProgram();

(() => { // Count up numbers
    const animationDuration = 2400;
    const frameDuration = 1000 / 60;
    const totalFrames = Math.round( animationDuration / frameDuration );
    const easeOutQuad = (t: number) => t * ( 2 - t );

    const animateCountUp = (el: HTMLElement) => {
        if (el.classList.contains("is-counted"))
            return;

        el.classList.add("is-counted")
        el.innerHTML = el.innerHTML.replace(/([0-9]+)/g, '<span>$1</span>');
        let frame = 0;

        const number = el.querySelector('span')
        if (number.nextElementSibling?.nodeName === "SPAN") {
            number.innerHTML += number.nextElementSibling.innerHTML
            number.nextElementSibling.remove()
        }

        const countTo = parseInt( number.innerHTML, 10 );
        const counter = setInterval( () => {
            frame++;
            const progress = easeOutQuad( frame / totalFrames ),
                currentCount = Math.round( countTo * progress );

            if ( parseInt( number.innerHTML, 10 ) !== currentCount )
                number.innerHTML = currentCount.toLocaleString()

            frame === totalFrames && clearInterval( counter )
        }, frameDuration );
    };

    function isElementInViewport (el: HTMLElement) {
        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }

    const runCountUp = () => {
        document.querySelectorAll('[data-about-number]').forEach( (el: HTMLElement) => {
            isElementInViewport(el) && animateCountUp(el);
        });
    }

    runCountUp();
    window.addEventListener("scroll", runCountUp);
})();

declare global {
    interface Window {
        loadMap: () => void
        initMap: () => void
        mapLoaded: boolean
        wpObject: {
            gMapsApi: string
            mapPosition: string
            mapAddressUrl: string
            mapIcon: string
            mapBackgroundColor: string
            mapPrimaryColor: string
        }
    }
}

(() => {
    window.mapLoaded = false

    const loadMap = () => {
        if (window.mapLoaded || !isInViewport(document.querySelector('[data-map-holder]'), 4))
            return

        window.mapLoaded = true

        const gMapsJs = document.createElement('script')
        gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_IckNle5OAQHtUqaSKFQHDtGe9nmJj_0&callback=initMap&libraries=&v=weekly`

        document.body.insertAdjacentElement('beforeend', gMapsJs)
    }

    document.addEventListener("DOMContentLoaded", loadMap);
    document.addEventListener("scroll", loadMap);
    window.loadMap = loadMap
})();

window.initMap = initMap;